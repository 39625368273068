import { OrganizationConstant } from '../constant';
const initialState = {
  payload: null,
  type: '',
  message: '',
  description: '',
  count: 0,
  organizationInformation: {},
};

export default function organizationReducers(state = initialState, action) {
  switch (action.type) {
    case OrganizationConstant.GETORGANIZATIONROLESSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        count: action.count,
        description: action.description,
      };
    case OrganizationConstant.GETORGANIZATIONROLESFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        count: action.count,
        description: action.description,
      };
    case OrganizationConstant.SAVEORGANIZATIONROLESUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case OrganizationConstant.SAVEORGANIZATIONDETAILSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };

    case OrganizationConstant.SAVEORGANIZATIONROLEFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case OrganizationConstant.DELETEORGANIZATIONROLESUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case OrganizationConstant.DELETEORGANIZATIONROLEFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case OrganizationConstant.GET_ORGANIZATION_INFO_SUCCESS:
      return {
        ...state,
        organizationInformation: action.payload,
      };
    case OrganizationConstant.UPDATE_ORGANIZATION_INFO_SUCCESS:
      return {
        ...state,
        type: OrganizationConstant.UPDATE_ORGANIZATION_INFO_SUCCESS,
      };
    case OrganizationConstant.UPDATE_ORGANIZATION_INFO_FAIL:
      return {
        ...state,
        type: OrganizationConstant.UPDATE_ORGANIZATION_INFO_FAIL,
      };
    default:
      return state;
  }
}
