import { EventConstant } from '../constant';
const initialState = {
  events: [],
  type: '',
  catchError: '',
  loading: false,
};

export default function eventsReducers(state = initialState, action) {
  switch (action.type) {
    case EventConstant.GET_EVENT_SUCCESS:
      return {
        ...state,
        events: action.payload,
        type: EventConstant.GET_EVENT_SUCCESS,
      };
    case EventConstant.GET_EVENT_FAIL:
      return {
        ...state,
        type: EventConstant.GET_EVENT_FAIL,
        catchError: action.catchError,
      };
    case EventConstant.LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    case EventConstant.LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
