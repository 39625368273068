export const SegmentConstant = {
  DEFAULTERR: 'sorry unable to process! please try again later',

  GETSEGMENTLISTSUCCESS: 'GET_SEGMENT_LIST_SUCCESS',
  GETSEGMENTLISTFAIL: 'GET_SEGMENT_LIST_FAIL',

  SAVESEGMENTSUCCESS: 'SAVE_SEGMENT_SUCCESS',
  SAVESEGMENTFAIL: 'SAVE_SEGMENT_FAIL',

  DELETESEGMENTSUCCESS: 'DELETE_SEGMENT_SUCCESS',
  DELETESEGMENTFAIL: 'DELETE_SEGMENT_FAIL',
  SET_IS_NEW_RULE: 'SET_IS_NEW_RULE',
  CLEAR_TYPE: 'CLEAR_TYPE',
};
