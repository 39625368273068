import { CampaignConstant } from '../constant';
const initialState = {
  payload: null,
  statusUpdateCampaign: null,
  type: '',
  message: '',
  contentExperience: {},
  isGeneral: false,
  abTestingDetailCopy: {},
  updatedDefaultEntries: [],
  personalizationDetailCopy: {},
  loading: false,
};

export default function campaignReducers(state = initialState, action) {
  switch (action.type) {
    case CampaignConstant.UPDATECAMPAIGNSUCCESS:
      return {
        ...state,
        updatedDefaultEntries: action.payload,
      };
    case CampaignConstant.GETCAMPAIGNLISTSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.GETCAMPAIGNLISTFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.SAVECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.SAVECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.DELETECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.DELETECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.CHANGECAMPAIGNPRIORITYSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.CHANGECAMPAIGNPRIORITYFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.SAVECONTENTABTESTCAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.SAVECONTENTABTESTCAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_FAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.GET_CONTENT_EXPERINCE_SUCCESS:
      return {
        ...state,
        abTestingDetail: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.GET_CONTENT_EXPERINCE_COPY_SUCCESS:
      return {
        ...state,
        abTestingDetail: action.payload,
        type: CampaignConstant.GET_CONTENT_EXPERINCE_SUCCESS,
        message: action.message,
        description: action.description,
        abTestingDetailCopy: action.payload,
      };

    case CampaignConstant.GET_CONTENT_EXPERINCE_FAIL:
      return {
        ...state,
        abTestingDetail: null,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.GET_CONTENT_PERSONALIZATION_SUCCESS:
      return {
        ...state,
        personalizationDetail: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.UPDATE_CONTENT_STATUS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.UPDATE_CONTENT_STATUS_FAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };

    case CampaignConstant.GET_CONTENT_PERSONALIZATION_COPY_SUCCESS:
      return {
        ...state,
        personalizationDetailCopy: action.payload,
        personalizationDetail: action.payload,
        type: CampaignConstant.GET_CONTENT_PERSONALIZATION_SUCCESS,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.GET_CONTENT_PERSONALIZATION_FAIL:
      return {
        ...state,
        personalizationDetail: null,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.CLEAR_DATA:
      return {
        ...state,
        type: action.type,
      };
    case CampaignConstant.DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case CampaignConstant.DELETE_CONTENT_FAIL:
      return {
        ...state,
        type: action.type,
      };
    case CampaignConstant.SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case CampaignConstant.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case CampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    case CampaignConstant.UPDATE_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        type: action.type,
        message: action.message,
        statusUpdateCampaign: action.payload,
      };
    case CampaignConstant.UPDATE_CAMPAIGN_STATUS_FAIL:
      return {
        ...state,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.CLEAR_ALL_DATA:
      return initialState;

    // eslint-disable-next-line no-duplicate-case
    case CampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
}
