import { AuthConstant } from '../constant';
const initialState = {
  payload: null,
  type: '',
  error: '',
  methodParameters: {},
  count_403: 0,
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case AuthConstant.LOGINSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
        count_403: state.count_403,
      };
    case AuthConstant.LOGINFAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
        count_403: state.count_403,
      };
    case AuthConstant.REGISTERSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
        count_403: state.count_403,
      };
    case AuthConstant.REGISTERFAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
        count_403: state.count_403,
      };
    case AuthConstant.VERIFYSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
        count_403: state.count_403,
      };
    case AuthConstant.VERIFYFAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
        count_403: state.count_403,
      };
    case AuthConstant.INVALID_TOKEN:
      return {
        type: action.type,
        message: action.message,
        methodParameters: action.methodParameters,
        count_403: state.count_403,
      };
    case AuthConstant.SET_403_ERROR_COUNT:
      return {
        ...state,
        count_403: state?.count_403 + 1,
      };
    case AuthConstant.SET_403_ERROR_COUNT_0:
      return {
        ...state,
        count_403: 0,
      };
    default:
      return state;
  }
}
