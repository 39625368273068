/* eslint-disable prettier/prettier */
import React, { useMemo, lazy, useEffect } from 'react';
import { locations } from '@contentful/app-sdk';
import { useSDK, useCMA } from '@contentful/react-apps-toolkit';
import { sha256 } from 'js-sha256';
import Animation403 from 'components/Animation/Animation403.js';
import { useSelector } from 'react-redux';
const ConfigScreen = lazy(() => import('./locations/configScreen'));
const EntryEditor = lazy(() => import('./locations/entryEditor'));
const Field = lazy(() => import('./locations/field'));
const Page = lazy(() => import('./locations/page'));
const ComponentLocationSettings = {
  [locations.LOCATION_APP_CONFIG]: ConfigScreen,
  [locations.LOCATION_ENTRY_FIELD]: Field,
  [locations.LOCATION_ENTRY_EDITOR]: EntryEditor,
  [locations.LOCATION_PAGE]: Page,
};

const App = () => {
  const sdk = useSDK();
  const { space, environment, organization, user, app } = sdk.ids;
  const { count_403 } = useSelector((state) => state.auth);

  const authCheckKey = sha256(
    `userAuthDetail_${organization}_${space}_${environment}_${user}`
  );
  localStorage.setItem('authKey', authCheckKey);
  const handleVisibilityChange = () => {
    const authCheckKey = sha256(
      `userAuthDetail_${organization}_${space}_${environment}_${user}`
    );
    localStorage.setItem('authKey', authCheckKey);
  };
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const cma = useCMA();
  const Component = useMemo(() => {
    for (const [location, component] of Object.entries(
      ComponentLocationSettings
    )) {
      if (sdk.location.is(location)) {
        return component;
      }
    }
  }, [sdk.location]);

  if (count_403 > 2) {
    return <Animation403 sdk={sdk} />;
  } else if (Component) {
    return <Component sdk={sdk} cma={cma} />;
  }
};

export default App;
