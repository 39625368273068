/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid';
import { CreateEligibilityCampaignConstant } from '../constant';

const initialState = {
  type: '',
  general: null,
  audiences: [],
  selectedChannels: [],
  openedChannel: '',
  openedCampaign: '',
  openedAudience: '',
  openedSegment: '',
  openedCampaignEndDate: '',
  openedCampaignStartDate: '',
};

let tmpAudiences = [];
let removedChannelArr = [];
let removedChannel = null;
let removedAudienceArr = [];

export default function createEligibilityCampaignReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case CreateEligibilityCampaignConstant.ADDAUDIENCE: {
      return {
        ...state,
        audiences: [{ ...action.payload, id: uuidv4() }, ...state.audiences],
      };
    }

    case CreateEligibilityCampaignConstant.UPDATEAUDIENCE: {
      tmpAudiences = [
        { ...action.payload },
        ...state.audiences.filter((ele) => ele?.id != action?.payload?.id),
      ];
      return {
        ...state,
        audiences: tmpAudiences,
      };
    }

    case CreateEligibilityCampaignConstant.REMOVEAUDIENCE: {
      return {
        ...state,
        audiences: state.audiences.filter((ele) => ele?.id != action.key),
      };
    }

    case CreateEligibilityCampaignConstant.SETSELECTEDCHANNELS: {
      tmpAudiences = state.audiences;
      removedChannelArr = state.selectedChannels?.filter(
        ({ value: chl1 }) =>
          !action.payload.some(({ value: chl2 }) => chl1 === chl2)
      );
      if (removedChannelArr?.length > 0) {
        removedChannel = removedChannelArr[0];
        tmpAudiences = removedChannel
          ? state.audiences?.filter(
              (ele, i) => ele.channel != removedChannel?.value
            )
          : state.audiences;

        removedAudienceArr = state?.audiences?.filter(
          ({ id: audId1 }) =>
            !tmpAudiences.some(({ id: audId2 }) => audId1 === audId2)
        );
      }

      return {
        ...state,
        selectedChannels: action.payload,
        audiences: tmpAudiences,
      };
    }

    case CreateEligibilityCampaignConstant.SETOPENEDCAMPAIGN: {
      return {
        ...state,
        openedCampaign: action.openedCampaign,
      };
    }

    case CreateEligibilityCampaignConstant.SETOPENEDCHANNEL: {
      return {
        ...state,
        openedChannel: action.key,
      };
    }

    case CreateEligibilityCampaignConstant.SETOPENEDAUDIENCE: {
      return {
        ...state,
        openedAudience: action.key,
      };
    }

    case CreateEligibilityCampaignConstant.SETOPENEDCAMPAIGNENDDATE: {
      return {
        ...state,
        openedCampaignEndDate: action.openedCampaignEndDate,
      };
    }

    case CreateEligibilityCampaignConstant.SETOPENEDCAMPAIGNSTARTDATE: {
      return {
        ...state,
        openedCampaignStartDate: action.openedCampaignStartDate,
      };
    }

    case CreateEligibilityCampaignConstant.CLEARALL: {
      return {
        ...state,
        general: null,
        audiences: [],
        selectedChannels: [],
        openedChannel: '',
        openedCampaign: '',
        openedAudience: '',
        type: CreateEligibilityCampaignConstant.CLEARALL,
      };
    }

    case CreateEligibilityCampaignConstant.SETAUDIENCES: {
      return {
        ...state,
        audiences: action.payload,
      };
    }

    default:
      return state;
  }
}
