import { TableConstant } from '../constant';
const initialState = {
  payload: '',
  type: '',
  message: '',
  loading: false,
  selectedTable: [],
};

export default function tableReducers(state = initialState, action) {
  switch (action.type) {
    case TableConstant.DEFAULTERR: {
      return initialState;
    }
    case TableConstant.CLEAR_TYPE: {
      return {
        ...state,
        type: '',
      };
    }
    case TableConstant.SET_SELECTED_TABLES:
      return {
        ...state,
        selectedTable: action.payload,
      };
    case TableConstant.TABLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case TableConstant.GETTABLELISTSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        count: action.count,
        message: action.message,
        description: action.description,
        loading: false,
      };
    case TableConstant.GETTABLELISTFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        count: action.count,
        message: action.message,
        description: action.description,
        loading: false,
      };
    case TableConstant.SAVETABLESUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case TableConstant.SAVETABLEFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case TableConstant.CHANGETABLESTATUSSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case TableConstant.CHANGETABLESTATUSFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    default:
      return state;
  }
}
